<template>
    <v-container id="terms">
        <v-row>
            <v-col cols="10"></v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useConstants } from "@/const/const.js";

export default {
    name: "SupportView",
    components: {},
    setup() {
        const constants = useConstants();
        return {
            constants,
        };
    },
};
</script>
